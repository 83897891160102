#contact_us_form {
    .form_input_container {
        justify-content: center;
    }
}

.form_input_container.verify_email {
    position: absolute;
    top: 0;
    right: -100rem;
    display: none;
}