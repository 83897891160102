@import "../../constants";

.contact_us {
    background-color: $main_color;
}
.contact_us_message {
    padding: 1.5rem 15vw;
    text-align: center;
}
@media only screen and (min-width:450px) {
    .contact_us_message {
        padding: 2rem 16vw;
    }
}
@media only screen and (min-width:50em) {
    .contact_us_message {
        padding: 3rem 18vw;
    }
}
@media only screen and (min-width:932px) {
    .contact_us_message {
        white-space: pre-wrap;
    }
}
