
.form_button {
    display: flex;
    &:hover {
        cursor: pointer;
    }
}
.send_message_container {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.send_message_text {
    padding-bottom: 8px;
}

// ----------------- Check Mark ----------------- //
.check_mark {
    border: #07af02 solid 3px;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 0.5rem;
    margin-left: 1.5rem;
}
.check_line {
    height: 4px;
    background-color: #07af02;
    transition: 0.4s;
    border-radius: 1rem
}
.check_bottom {
    -webkit-transform: rotate(48deg) translate(-5px, -1px);
    transform: rotate(48deg) translate(-5px, -1px);
    width: 14px;
}
.check_side {
    width: 22px;
    margin: 6px 0;
    -webkit-transform: rotate(-48deg) translate(0.5px, 4.25px);
    transform: rotate(-48deg) translate(0.5px, 4.25px);
}

// ----------------- Arrow ----------------- //
.arrow {
    border: white solid 1px;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
    margin-left: 1rem;
}
.arrow_line {
    height: 4px;
    background-color: #ffffff;
    transition: 0.4s;
    border-radius: 1rem
}
.arrow_top {
    -webkit-transform: rotate(45deg) translate(9px, -1.5px);
    transform: rotate(45deg) translate(9px, -1.5px);
    width: 16px;
}
.arrow_middle {
    width: 22px;
    margin: 6px 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.arrow_bottom {
    -webkit-transform: rotate(-45deg) translate(9px, 1.5px);
    transform: rotate(-45deg) translate(9px, 1.5px);
    width: 16px;
}


@media only screen and (min-width:450px) {
    .check_mark {
        padding: 0.6rem;
    }
    .arrow {
        padding: 0.6rem 0.5rem 0.6rem 0.5rem;
    }
    .arrow_top {
        -webkit-transform: rotate(45deg) translate(10.5px, -3.5px);
        transform: rotate(45deg) translate(10.5px, -3.5px);
        width: 18px;
    }
    .arrow_middle {
        width: 26px;
    }
    .arrow_bottom {
        -webkit-transform: rotate(-45deg) translate(10.5px, 3.5px);
        transform: rotate(-45deg) translate(10.5px, 3.5px);
        width: 18px;
    }
}

@media only screen and (min-width:50em) {
    .check_mark {
        margin-left: 2rem;
        padding: 0.7rem;
    }
    .arrow {
        margin-left: 1.25rem;
        padding: 0.7rem 0.5rem 0.7rem 0.5rem;
    }
    .arrow_top {
        -webkit-transform: rotate(45deg) translate(12px, -6px);
        transform: rotate(45deg) translate(12px, -6px);
        width: 20px;
    }
    .arrow_middle {
        width: 30px;
    }
    .arrow_bottom {
        -webkit-transform: rotate(-45deg) translate(12px, 6px);
        transform: rotate(-45deg) translate(12px, 6px);
        width: 20px;
    }
}