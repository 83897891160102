
.full_home_header {
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 90;
}
.home_header_img_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 24;
    height: 100%;
    overflow: hidden;
}
.home_image_overlay {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #757575;
    mix-blend-mode: darken;
}
.home_header_img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.home_header_title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 87px;
    z-index: 26;
}
.home_header_title_elm {
    width: 90vw;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 2rem;
    margin-left: 2rem;
}
.logo_text {
    max-width: 75vw;
}
.logo_fire {
    max-height: 14vw;
}


@media only screen and (min-width:550px) {
    /* ---------------------------------- */
    /* ------------- HEADER ------------- */
    /* ---------------------------------- */
    .home_header_title_elm {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .logo_text {
        max-width: 80vw;
    }
    .logo_fire {
        max-height: 16vw;
    }
}

@media only screen and (min-width:50em) {
    .logo_fire {
        max-height: 20vw;
    }
}


@media only screen and (min-width:1058px) {
    .home_logo {
        margin-top: 3rem;
    }
    .perfect_practice {
        margin-bottom: 3.5rem;
    }
}
