$main_color: #676F76;
$secondary_color: #E8E5E5;
$main_red: #ac2803;
$main_highlight: #2FCC8B;

$shadow_props: 0px 4px 4px rgba(0, 0, 0, 0.25);
$shadow: drop-shadow($shadow_props);
$red_text_shadow: 0px 4px 4px rgba(209, 47, 0, 0.25);

$menu_collapse_width: 1146px;

