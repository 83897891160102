.full_home_header {
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  z-index: 90;
  position: relative;
}

.home_header_img_container {
  z-index: 24;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.home_image_overlay {
  mix-blend-mode: darken;
  background: #757575;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.home_header_img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.home_header_title {
  z-index: 26;
  flex-direction: column;
  align-items: center;
  padding-bottom: 87px;
  display: flex;
  position: relative;
}

.home_header_title_elm {
  width: 90vw;
  text-align: center;
  margin: .5rem 2rem;
}

.logo_text {
  max-width: 75vw;
}

.logo_fire {
  max-height: 14vw;
}

@media only screen and (min-width: 550px) {
  .home_header_title_elm {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .logo_text {
    max-width: 80vw;
  }

  .logo_fire {
    max-height: 16vw;
  }
}

@media only screen and (min-width: 50em) {
  .logo_fire {
    max-height: 20vw;
  }
}

@media only screen and (min-width: 1058px) {
  .home_logo {
    margin-top: 3rem;
  }

  .perfect_practice {
    margin-bottom: 3.5rem;
  }
}

.form_button {
  display: flex;
}

.form_button:hover {
  cursor: pointer;
}

.send_message_container {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.send_message_text {
  padding-bottom: 8px;
}

.check_mark {
  box-sizing: border-box;
  border: 3px solid #07af02;
  border-radius: 50%;
  margin-left: 1.5rem;
  padding: .5rem;
}

.check_line {
  height: 4px;
  background-color: #07af02;
  border-radius: 1rem;
  transition: all .4s;
}

.check_bottom {
  width: 14px;
  -webkit-transform: rotate(48deg)translate(-5px, -1px);
  transform: rotate(48deg)translate(-5px, -1px);
}

.check_side {
  width: 22px;
  margin: 6px 0;
  -webkit-transform: rotate(-48deg)translate(.5px, 4.25px);
  transform: rotate(-48deg)translate(.5px, 4.25px);
}

.arrow {
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-left: 1rem;
  padding: .4rem .5rem;
}

.arrow_line {
  height: 4px;
  background-color: #fff;
  border-radius: 1rem;
  transition: all .4s;
}

.arrow_top {
  width: 16px;
  -webkit-transform: rotate(45deg)translate(9px, -1.5px);
  transform: rotate(45deg)translate(9px, -1.5px);
}

.arrow_middle {
  width: 22px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 6px 0;
}

.arrow_bottom {
  width: 16px;
  -webkit-transform: rotate(-45deg)translate(9px, 1.5px);
  transform: rotate(-45deg)translate(9px, 1.5px);
}

@media only screen and (min-width: 450px) {
  .check_mark {
    padding: .6rem;
  }

  .arrow {
    padding: .6rem .5rem;
  }

  .arrow_top {
    width: 18px;
    -webkit-transform: rotate(45deg)translate(10.5px, -3.5px);
    transform: rotate(45deg)translate(10.5px, -3.5px);
  }

  .arrow_middle {
    width: 26px;
  }

  .arrow_bottom {
    width: 18px;
    -webkit-transform: rotate(-45deg)translate(10.5px, 3.5px);
    transform: rotate(-45deg)translate(10.5px, 3.5px);
  }
}

@media only screen and (min-width: 50em) {
  .check_mark {
    margin-left: 2rem;
    padding: .7rem;
  }

  .arrow {
    margin-left: 1.25rem;
    padding: .7rem .5rem;
  }

  .arrow_top {
    width: 20px;
    -webkit-transform: rotate(45deg)translate(12px, -6px);
    transform: rotate(45deg)translate(12px, -6px);
  }

  .arrow_middle {
    width: 30px;
  }

  .arrow_bottom {
    width: 20px;
    -webkit-transform: rotate(-45deg)translate(12px, 6px);
    transform: rotate(-45deg)translate(12px, 6px);
  }
}

#contact_us_form .form_input_container {
  justify-content: center;
}

.form_input_container.verify_email {
  display: none;
  position: absolute;
  top: 0;
  right: -100rem;
}

.contact_us {
  background-color: #676f76;
}

.contact_us_message {
  text-align: center;
  padding: 1.5rem 15vw;
}

@media only screen and (min-width: 450px) {
  .contact_us_message {
    padding: 2rem 16vw;
  }
}

@media only screen and (min-width: 50em) {
  .contact_us_message {
    padding: 3rem 18vw;
  }
}

@media only screen and (min-width: 932px) {
  .contact_us_message {
    white-space: pre-wrap;
  }
}

.about_us_img_container {
  padding: 2rem;
  position: relative;
}

.about_us_img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  display: block;
  box-shadow: 0 4px 10px #343a40;
}

.about_us_img_text {
  white-space: pre-wrap;
  background-color: #676f76;
  padding: 1rem;
  box-shadow: 0 4px 10px #343a40;
}

.about_us_title {
  margin-bottom: 1rem;
}

.about_us_subparagraph {
  margin-top: 1rem;
}

@media only screen and (min-width: 700px) {
  .about_us_img_text {
    width: 60%;
    position: absolute;
    bottom: 4rem;
    left: 4rem;
  }
}

@media only screen and (min-width: 850px) {
  .about_us_img {
    height: 470px;
  }

  .about_us_img_text {
    width: 550px;
  }
}

@media only screen and (min-width: 950px) {
  .about_us_img_container {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media only screen and (min-width: 1200px) {
  .about_us_link_container {
    padding-left: 15%;
    padding-right: 15%;
  }

  .about_us_img_container {
    display: flex;
  }

  .about_us_img_container:nth-child(2n+1) {
    flex-direction: row-reverse;
  }

  .about_us_img_container:nth-child(2n) > .about_us_img {
    z-index: 10;
  }

  .about_us_img {
    width: 70%;
    height: 550px;
  }

  .about_us_img_text {
    position: initial;
    width: 30%;
    flex-direction: column;
    display: flex;
  }

  .about_us_title {
    border-bottom: 1px solid #fff;
    padding-bottom: .25rem;
  }

  .about_us_text {
    flex: 1;
    font-size: 1.1rem;
  }

  .class_offerings_list {
    margin-top: 0;
    margin-bottom: 0;
  }

  .class_offerings_list:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    padding-left: 20px;
  }

  .class_offerings_list:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    padding-right: 20px;
  }

  .class_offerings_list:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    padding-right: 20px;
  }
}

@media only screen and (min-width: 1356px) {
  .about_us_link_container {
    justify-content: space-evenly;
    display: flex;
  }
}

@media only screen and (min-width: 1500px) {
  .about_us_img_text {
    padding: 2rem;
  }

  .about_us_img {
    height: 650px;
  }

  .about_us_title {
    margin-bottom: 2rem;
  }

  .about_us_text {
    font-size: 1.5rem;
  }

  .class_offerings_list {
    font-size: 1.3rem;
  }

  .about_us_subparagraph {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 1532px) {
  .class_offerings_list {
    font-size: 1.35rem;
  }
}

@media only screen and (min-width: 1800px) {
  .about_us_img_text {
    padding: 2rem 2.4rem;
  }

  .about_us_title {
    margin-bottom: 2.5rem;
  }

  .about_us_subparagraph {
    margin-top: 2.5rem;
  }
}

.about_us_link {
  color: #343a40;
  text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  text-align: center;
  margin: .5rem 0;
}

.about_us_link.selected {
  color: #ac2803;
  text-shadow: 0 4px 4px rgba(209, 47, 0, .25);
}

.about_us_link:hover {
  text-shadow: none;
}

.about_us_link_container {
  align-items: center;
  justify-items: center;
  gap: 2%;
  padding: 1.5rem 2rem;
  display: grid;
}

@media only screen and (min-width: 597px) {
  .about_us_link_container {
    justify-content: space-evenly;
    display: flex;
  }
}

.about_us {
  background: #e8e5e5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

.mission_statement {
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  background-color: #676f76;
  padding: 3rem 2rem;
}

@media only screen and (min-width: 1058px) {
  .mission_statement {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

/*# sourceMappingURL=index.4f75ff66.css.map */
