
.about_us_img_container {
    position: relative;
    padding: 2rem;
}

.about_us_img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    box-shadow: 0px 4px 10px #343A40;
    display: block;
}

.about_us_img_text {
    white-space: pre-wrap;
    background-color: #676F76;
    box-shadow: 0px 4px 10px #343A40;
    padding: 1rem;
}

.about_us_title {
    margin-bottom: 1rem;
}

.about_us_subparagraph {
    margin-top: 1rem;
}
@media only screen and (min-width:700px) {
    .about_us_img_text {
        position: absolute;
        bottom: 4rem;
        left: 4rem;
        width: 60%;
    }
}

@media only screen and (min-width:850px) {
    .about_us_img {
        height: 470px;
    }
    .about_us_img_text {
        width: 550px;
    }
}

@media only screen and (min-width:950px) {
    .about_us_img_container {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media only screen and (min-width:1200px) {
    .about_us_link_container {
        padding-left: 15%;
        padding-right: 15%;
    }
    .about_us_img_container {
        display: flex;
    }
    .about_us_img_container:nth-child(odd) {
        flex-direction: row-reverse;
    }
    .about_us_img_container:nth-child(even) > .about_us_img {
        z-index: 10;
    }
    .about_us_img {
        width: 70%;
        height: 550px;
    }
    .about_us_img_text {
        position: initial;
        width: 30%;
        display: flex;
        flex-direction: column;
    }
    .about_us_title {
        padding-bottom: 0.25rem;
        border-bottom: white 1px solid;
    }
    .about_us_text {
        flex: 1;
        font-size: 1.1rem;
    }
    .class_offerings_list {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 20px;
    }
}
@media only screen and (min-width:1356px) {
    .about_us_link_container {
        display: flex;
        justify-content: space-evenly;
    }
}
@media only screen and (min-width:1500px) {
    .about_us_img_text {
        padding: 2rem;
    }
    .about_us_img {
        height: 650px;
    }
    .about_us_title {
        margin-bottom: 2rem;
    }
    .about_us_text {
        font-size: 1.5rem;
    }
    .class_offerings_list {
        font-size: 1.3rem;
    }
    .about_us_subparagraph {
        margin-top: 2rem;
    }
}
@media only screen and (min-width:1532px) {
    .class_offerings_list {
        font-size: 1.35rem;
    }
}
@media only screen and (min-width:1800px) {
    .about_us_img_text {
        padding: 2rem 2.4rem;
    }
    .about_us_title {
        margin-bottom: 2.5rem;
    }
    .about_us_subparagraph {
        margin-top: 2.5rem;
    }
}