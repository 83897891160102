
.about_us_link_container {
    padding: 1.5rem 2rem 1.5rem 2rem;
    display: grid;
    gap: 2%;
    justify-items: center;
    align-items: center;
}
@media only screen and (min-width:597px) {
    .about_us_link_container {
        display: flex;
        justify-content: space-evenly;
    }
}
