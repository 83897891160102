@import "../../constants";

.about_us_link {
    margin: 0.5rem 0 0.5rem 0;
    color: #343A40;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
}

.about_us_link.selected {
    color: $main_red;
    text-shadow: 0px 4px 4px rgba(209, 47, 0, 0.25);
}

.about_us_link:hover {
    text-shadow: none;
}
