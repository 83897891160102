
.mission_statement {
    text-align: center;
    padding: 3rem 2rem 3rem 2rem;
    background-color: #676F76;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

@media only screen and (min-width:1058px) {
    .mission_statement {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}